<template>
  <div class="chat__contact flex items-center px-2 pt-4 pb-2">
    <Avatar class="mr-1" size="38px" :name="chat.name" />

    <div class="contact__container w-full flex items-center justify-between overflow-hidden">
      <div class="contact__info flex flex-col mt-2 w-5/6">
        <h5 class="font-semibold font-medium">{{ chat.name }}</h5>
        <span class="">{{ chat.message }}</span>
      </div>

      <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
        <span class="whitespace-no-wrap font-small mb-12">{{ chat_time(chat.time) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
import { mapGetters } from 'vuex'

import Avatar from '@/components/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    chat: { type: Object, required: true }
  },
  computed: {
    ...mapGetters({
      users: 'users'
    }),
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    locale() {
      return this.activeUserInfo.language ? this.activeUserInfo.language : this.$defaultLanguage
    }
  },

  methods: {
    chat_time(time) {
      return dayjs(time).format('LLL')
    }
  }
}
</script>
<style>
.font-small {
  font-size: 0.8rem !important;
}
.font-medium {
  font-size: 1rem !important;
}
</style>
