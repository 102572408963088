/* eslint-disable */
export const mcolorwheel = {
  methods: {
    getContrastColor(color) {
      let r = parseInt(color.slice(1, 3), 16)
      let g = parseInt(color.slice(3, 5), 16)
      let b = parseInt(color.slice(5, 7), 16)
      let yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? '#000000' : '#FFFFFF'
    },

    generateColorWheel(baseColor, numberOfColors) {
      let [h, s, l] = this.hexToHSL(baseColor)
      let colorWheel = []
      let step = 1 / (numberOfColors + 1)

      for (let i = 1; i <= numberOfColors; i++) {
        let newH = (h + i * step) % 1
        let newColor = this.hslToHex(newH, s, l)
        let newTextColor = this.getContrastColor(newColor)
        colorWheel.push({ backgroundColor: newColor, textColor: newTextColor })
      }

      return colorWheel
    },

    generateColorWheelMC(baseColor, numberOfColors) {
      let [h, s, l] = this.hexToHSL(baseColor)
      let colorWheel = []
      let step = 1 / (numberOfColors + 1)

      for (let i = 1; i <= numberOfColors; i++) {
        let newL = i * step
        let newColor = this.hslToHex(h, s, newL)
        let newTextColor = this.getContrastColor(newColor)
        colorWheel.push({ backgroundColor: newColor, textColor: newTextColor })
      }

      return colorWheel
    },

    hexToHSL(hex) {
      let r = parseInt(hex.slice(1, 3), 16) / 255
      let g = parseInt(hex.slice(3, 5), 16) / 255
      let b = parseInt(hex.slice(5, 7), 16) / 255

      let max = Math.max(r, g, b)
      let min = Math.min(r, g, b)
      let h,
        s,
        l = (max + min) / 2

      if (max === min) {
        h = s = 0
      } else {
        let d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
          case g:
            h = (b - r) / d + 2
            break
          case b:
            h = (r - g) / d + 4
            break
        }

        h /= 6
      }

      return [h, s, l]
    },

    hslToHex(h, s, l) {
      let hueToRgb = (p, q, t) => {
        if (t < 0) t += 1
        if (t > 1) t -= 1
        if (t < 1 / 6) return p + (q - p) * 6 * t
        if (t < 1 / 2) return q
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
        return p
      }

      let r, g, b

      if (s === 0) {
        r = g = b = l
      } else {
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s
        let p = 2 * l - q
        r = hueToRgb(p, q, h + 1 / 3)
        g = hueToRgb(p, q, h)
        b = hueToRgb(p, q, h - 1 / 3)
      }

      return '#' + ((1 << 24) + (Math.round(r * 255) << 16) + (Math.round(g * 255) << 8) + Math.round(b * 255)).toString(16).slice(1)
    }
  }
}
