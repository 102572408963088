var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "online-meeting-page-wrapper" },
    [
      _c("div", { staticClass: "mb-base flex justify-between" }, [
        _c("div", { staticClass: "action-buttons w-full mr-5" }, [
          _c(
            "div",
            [
              _c(
                "vs-button",
                {
                  staticClass: "action-button",
                  class: !_vm.HAS_ACCESS ? "disabled-button" : "",
                  attrs: {
                    color: "primary",
                    type: "filled",
                    icon: "add",
                    disabled: !_vm.HAS_ACCESS,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleSidebar.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("vue.addMeeting")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.IS_ADMIN
            ? _c(
                "div",
                { staticClass: "select-agents" },
                [
                  _c("multiselect", {
                    ref: "country",
                    attrs: {
                      options: _vm.agents,
                      multiple: true,
                      max: 10,
                      "close-on-select": true,
                      "clear-on-select": false,
                      placeholder: _vm.$t("vue.pleaseSelectAgents"),
                      selectLabel: "",
                      selectedLabel: "",
                      deselectLabel: "",
                      label: "name",
                      "track-by": "name",
                      name: _vm.$t("inputs.agents"),
                      "open-direction": "bottom",
                      "data-vv-as": _vm.$t("inputs.agents"),
                      "preserve-search": true,
                      "preselect-first": false,
                    },
                    on: {
                      select: _vm.onSelectedAgentsUpdated,
                      remove: _vm.onRemovedAgent,
                    },
                    model: {
                      value: _vm.selectedAgents,
                      callback: function ($$v) {
                        _vm.selectedAgents = $$v
                      },
                      expression: "selectedAgents",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.IS_ADMIN
            ? _c(
                "div",
                { staticClass: "action-button child-action" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "action-button",
                      attrs: {
                        color: "primary",
                        type: "filled",
                        icon: "filter",
                        disabled:
                          this.selectedAgents.length === 0 ||
                          !_vm.canGetAgentMeetings,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.getAgentMeetings.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.loadMeetings")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          [
            _c("vs-button", {
              attrs: { color: "primary", type: "filled", icon: "refresh" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.refreshCalendar.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("MeetingList", {
        attrs: {
          modifiedMeetings: _vm.modifiedMeetings,
          isMobile: _vm.isMobile,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }