<template>
  <div>
    <indeterminate-loading :isLoading="isFetchingEvents" />
    <div class="vx-card p-6 online-meeting" ref="onlineMeetingCard" :class="{ 'mt-2': !isMobile, 'pt-3': isMobile }">
      <MeetingSidebar :isSidebarActive="isOpenedSidebar" :isMobile="isMobile" @closeAppointmentsSidebar="toggleSidebar" />

      <div :class="!HAS_ACCESS && !isMobileApp ? 'blur-background' : ''">
        <vs-sidebar
          click-not-close
          position-right
          parent="body"
          default-index="1"
          color="primary"
          class="message-data-sidebar items-no-padding"
          spacer
          v-model="isSidebarActiveLocal"
        >
          <div class="mt-6 flex items-center justify-between px-6">
            <h4>{{ $t('inputs.message') }}</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
          </div>

          <vs-divider class="mb-0"></vs-divider>

          <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div class="chat__chats-list mb-8">
              <ul class="chat__active-chats bordered-items">
                <li class="cursor-pointer">
                  <chat-message :chat="sidebarChatData"></chat-message>
                </li>
              </ul>
            </div>
          </component>
        </vs-sidebar>

        <div v-show="isLoaded" class="full-calendar-wrapper" ref="calendarWraper">
          <full-calendar v-show="isLoaded" ref="refCalendar" class="full-calendar" :key="calendarKey" :options="modifiedCalendarOptions">
            <template v-slot:eventContent="arg">
              <vx-tooltip
                v-if="arg && arg.event && arg.event.extendedProps && arg.event.extendedProps.displayName"
                :text="`${$t('inputs.title')}: ${arg.event.title}, ${$t('vue.time')}: ${getEventStartTime(arg.event.start)}, ${$t('vue.agent')}: ${
                  arg.event.extendedProps.displayName
                }`"
                position="top"
                :class="
                  arg && arg.view && ['listMonth', 'dayGridMonth'].includes(arg.view.type)
                    ? 'online-meeting--wrapper online-meeting--wrapper--row'
                    : 'online-meeting--wrapper online-meeting--wrapper--column'
                "
                :style="
                  arg && arg.view && arg.view.type === 'dayGridMonth' ? `background-color: ${arg.backgroundColor} !important; color: ${arg.textColor}; ` : ''
                "
              >
                <div class="fc-event-time">{{ getEventStartTime(arg.event.start) }}</div>
                <div class="fc-event-title" :class="{ 'fc-event-title--ellipsis': arg && arg.view && !['timeGridDay', 'listMonth'].includes(arg.view.type) }">
                  {{ arg.event.title }}
                </div>
                <div
                  v-if="arg && arg.event && arg.event.extendedProps && arg.event.extendedProps.visitorScore && arg.event.extendedProps.isVegaWidgetEnabled"
                  class="online-meeting--visitor-score"
                  :class="`online-meeting--${arg.view.type}`"
                >
                  <VisitorStatusIcon
                    :score="arg.event.extendedProps.visitorScore"
                    :online="true"
                    :isVegaWidgetEnabled="arg.event.extendedProps.isVegaWidgetEnabled"
                  />
                </div>
                <div class="online-meeting--agent-name" :class="`online-meeting--${arg.view.type}`">{{ arg.event.extendedProps.displayName }}</div>
              </vx-tooltip>

              <div
                v-else
                class="online-meeting--wrapper"
                :style="arg.backgroundColor ? `background-color: ${arg.backgroundColor} !important; color: ${arg.textColor}; ` : ''"
              >
                <div class="fc-event-time">{{ getEventStartTime(arg.event.start) }}</div>
                <div class="fc-event-title" :class="{ 'fc-event-title--ellipsis': arg && arg.view && !['timeGridDay', 'listMonth'].includes(arg.view.type) }">
                  {{ arg.event.title }}
                </div>
              </div>
            </template>
          </full-calendar>
        </div>
      </div>

      <online-meeting-message v-if="!HAS_ACCESS && !isMobileApp"></online-meeting-message>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import '@fullcalendar/core/locales/de'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { Device } from '@capacitor/device'

import _ from 'lodash'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import OnlineMeetingMessage from '@/components/upgrade-messages/OnlineMeetingMessage.vue'
import MeetingSidebar from './MeetingSidebar'
import ChatMessage from './ChatMessage.vue'
import IndeterminateLoading from '@/components/IndeterminateLoading.vue'
import VisitorStatusIcon from '@/components/vega/VisitorStatusIcon.vue'

const calendarsColor = {
  Primary: 'primary',
  Canceled: 'danger',
  Disabled: 'disabled'
}

export default {
  components: {
    FullCalendar,
    OnlineMeetingMessage,
    /* eslint-disable-next-line */
    VuePerfectScrollbar,
    /* eslint-disable-next-line */
    ChatMessage,
    MeetingSidebar,
    IndeterminateLoading,
    VisitorStatusIcon
  },

  props: {
    modifiedMeetings: {
      type: Array
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data: (data) => ({
    isMobileApp: false,
    DEFAULT_VIEW_ON_MOBILE: 'timeGridDay',
    isLoaded: false,
    isFetchingEvents: null,
    calendarKey: Math.random().toString(36).substring(2, 15),

    lastFetchedCalendarDateByMonth: null,

    isOpenedSidebar: false,
    isSidebarActiveLocal: false,
    sidebarChatData: {},

    calendarOptions: {
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      initialView: 'timeGridWeek',
      initialDate: new Date(),
      headerToolbar: {
        start: 'sidebarToggle, prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      },
      editable: false,
      dragScroll: false,
      dayMaxEvents: 3,
      locale: 'de',
      eventTimeFormat: (date) => {
        const updatedDate = dayjs(date.date.marker).utc()

        if (data.$i18n.locale === 'de') {
          return updatedDate.format('HH:mm')
        }

        return updatedDate.format('h:mm A')
      },
      eventClick({ event: clickedEvent }) {
        if (clickedEvent.extendedProps && clickedEvent.extendedProps.calendar === 'Disabled') {
          return
        }

        const id = clickedEvent.id
        const selectedMeeting = data.modifiedMeetings.find((item) => item.id === id)

        data.$serverBus.$emit('set-meeting-data', {
          meeting: selectedMeeting
        })

        data.isOpenedSidebar = true
      },
      eventClassNames({ event: calendarEvent }) {
        // eslint-disable-next-line no-underscore-dangle
        const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

        return [
          // Background Color
          `bg-light-${colorName}`
        ]
      },
      dateClick(info) {
        const isSameDate = dayjs(dayjs(info.date).format('YYYY-MM-DD')).isSame(dayjs(dayjs().format('YYYY-MM-DD')))

        if (!dayjs(info.date).isAfter(dayjs()) && !(info.allDay && isSameDate)) {
          return
        }

        data.$serverBus.$emit('set-meeting-data', {
          action: 'add',
          allDay: info.allDay,
          date: info.date
        })

        data.isOpenedSidebar = true
      },
      rerenderDelay: 350
    },

    settings: {
      maxScrollbarLength: 60,
      wheelSpeed: 0.6
    },

    key: Math.random().toString(36).substring(2, 15)
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      personalMeetings: 'onlineMeetings/personalMeetings',
      calendarView: 'onlineMeetings/calendarView'
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('online-meeting') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },

    modifiedPersonalMeetings() {
      return _.cloneDeep(this.personalMeetings).map((item) => {
        item.title = item.name
        item.start = dayjs(item.start).toISOString()
        item.end = dayjs(item.end).toISOString()
        item.allDay = item.isAllDay

        item.extendedProps = {
          calendar: 'Disabled'
        }

        return item
      })
    },

    modifiedCalendarOptions() {
      const calendarOptions = {
        ...this.calendarOptions
      }

      if (this.isMobile) {
        calendarOptions.headerToolbar.end = 'timeGridDay,listMonth'
      }

      return {
        ...calendarOptions,
        events: this.calendarView === 'listMonth' ? [...this.modifiedMeetings] : [...this.modifiedMeetings, ...this.modifiedPersonalMeetings]
      }
    }
  },

  created() {
    this.checkIsMobileApp()

    this.calendarOptions.initialView = this.isMobile ? this.DEFAULT_VIEW_ON_MOBILE : this.calendarView

    this.$serverBus.$on('refresh-meetings-calendar', () => {
      this.refreshCalendarEvents()
    })

    this.$serverBus.$on('open-meeting-sidebar', () => {
      this.$serverBus.$emit('set-meeting-data', {
        action: 'add'
      })

      this.isOpenedSidebar = true
    })

    this.$serverBus.$on('open-message-sidebar', (data) => {
      this.isSidebarActiveLocal = true
      this.sidebarChatData = data
    })

    this.$serverBus.$on('add-meeting', this.reloadCalendar)

    this.$serverBus.$on('close-meeting-sidebar', () => {
      this.reloadCalendar()

      this.isOpenedSidebar = false

      this.$serverBus.$emit('set-meeting-data', null)
    })
  },

  async mounted() {
    this.setCalendarLang()

    this.isLoaded = true
    this.$nextTick(() => {
      this.addListeners()
    })
    setTimeout(async () => {
      if (!this.personalMeetings || this.personalMeetings.length <= 0) {
        await this.refreshCalendarEvents()
      }
    }, 500)
  },

  async beforeDestroy() {
    await this.setLastReadPage()
    this.removeListeners()
    this.$serverBus.$off('add-meeting')
    this.$serverBus.$off('open-meeting-sidebar')
    this.$serverBus.$off('open-message-sidebar')
    this.$serverBus.$off('close-meeting-sidebar')
    this.$serverBus.$off('refresh-meetings-calendar')
  },

  watch: {
    '$i18n.locale'() {
      this.removeListeners()
      this.setCalendarLang()
      this.$nextTick(this.addListeners)
    },
    isFetchingEvents() {
      this.toggleCalendarButtons(this.isFetchingEvents)
    }
  },

  methods: {
    ...mapActions({
      updatePersonalMeetings: 'onlineMeetings/updatePersonalMeetings',
      updateCalendarView: 'onlineMeetings/updateCalendarView'
    }),

    getEventStartTime(start) {
      if (this.activeUserInfo && this.activeUserInfo.timezone) {
        return dayjs(start).utcOffset(this.activeUserInfo.timezone.dstOffset).format('hh:mm A')
      } else {
        return dayjs(start).format('hh:mm A')
      }
    },

    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    toggleCalendarButtons(disabled) {
      const calendarButtons = document.getElementsByClassName('fc-button')
      for (const fcButton of calendarButtons) {
        fcButton.disabled = disabled
      }
    },

    async refreshCalendarEvents() {
      this.isFetchingEvents = true
      if (this.activeUserInfo.isSyncedCalendar) {
        const meetings = await this.fetchCalendarEvents(this.activeUserInfo.uid)
        if (meetings && meetings.length > 0) {
          await this.updatePersonalMeetings([...meetings])
        }

        await this.setLastReadPage()
      }
      this.isFetchingEvents = false
    },

    async fetchCalendarEvents(userId, date = null) {
      const calednarApi = this.$refs.refCalendar && this.$refs.refCalendar.getApi()

      if (calednarApi) {
        this.lastFetchedCalendarDateByMonth = calednarApi.getDate()
      }

      try {
        const calendarGetListEvents = this.$functions.httpsCallable('calendarGetListEvents')
        const { data } = await calendarGetListEvents({ userId, syncedCalendarId: this.activeUserInfo.syncedCalendarId, date })

        return this.modifyEvents(data)
      } catch {
        return this.catchErrorForFetchingCalendarEvents()
      }
    },

    catchErrorForFetchingCalendarEvents() {
      return []
    },

    modifyEvents(list) {
      const tempEvents = []

      list.forEach((resource) => {
        const name = resource.summary || resource.subject
        const event = { name, privateEventId: resource.id }

        const { isAllDay } = resource

        if (isAllDay) {
          resource.start = {
            date: resource.start.dateTime
          }

          resource.end = {
            date: resource.end.dateTime
          }

          event.isAllDay = isAllDay
        }

        if (resource.start.date && resource.end.date) {
          event.isAllDay = true
        }

        event.start = this.modifyDate(resource.start)
        event.end = this.modifyDate(resource.end, true)

        const extensions = this.getEventExtensions(resource)

        if (extensions) {
          const { meetingId, salesAgent } = extensions

          if (meetingId) {
            event.id = meetingId
          }

          if (salesAgent) {
            event.salesAgent = salesAgent
          }
        }

        if (!event.id) {
          tempEvents.push(event)
        }
      })

      return tempEvents
    },

    getEventExtensions(resource) {
      if (resource.extendedProperties && resource.extendedProperties.private) {
        return resource.extendedProperties.private
      } else if (resource.extensions) {
        return resource.extensions
      }

      return null
    },

    modifyDate(date = {}, isEndDate) {
      const copyDate = { ...date }

      if (!copyDate.dateTime && !copyDate.date) return ''

      if (copyDate.dateTime && copyDate.timeZone) {
        const utcDate = dayjs.tz(copyDate.dateTime, copyDate.timeZone).utc().format()
        const localDate = new Date(utcDate).toString()

        copyDate.dateTime = localDate
      }

      const modifiedDate = dayjs(copyDate.date || copyDate.dateTime)

      if (copyDate.date) {
        if (isEndDate) {
          modifiedDate.subtract(1, 'days')
        }

        return modifiedDate.format('YYYY-MM-DD')
      }

      return modifiedDate.format('YYYY-MM-DD HH:mm:ss')
    },

    handleNextButtonCalendarClick(event) {
      const $el = event.target
      const tagNameLowerCase = $el.tagName.toLowerCase()
      const classListArray = [...$el.classList]

      const calendarApi = this.$refs.refCalendar && this.$refs.refCalendar.getApi()
      if (calendarApi) {
        this.updateCalendarView(calendarApi.view.type)
      }

      if (tagNameLowerCase === 'span' && classListArray.includes('fc-icon-chevron-right')) {
        this.getFutureCalendarEvents()
      } else if (tagNameLowerCase === 'button' && classListArray.includes('fc-next-button')) {
        this.getFutureCalendarEvents()
      }
    },

    async getFutureCalendarEvents() {
      const calednarApi = this.$refs.refCalendar && this.$refs.refCalendar.getApi()

      if (calednarApi && this.lastFetchedCalendarDateByMonth) {
        const modifedCurrentCalendarDate = new Date(calednarApi.getDate().setDate(7))

        if (dayjs(dayjs(modifedCurrentCalendarDate).format('YYYY-MM')).isAfter(dayjs(dayjs(this.lastFetchedCalendarDateByMonth).format('YYYY-MM')))) {
          if (this.activeUserInfo.isSyncedCalendar) {
            this.isFetchingEvents = true

            const futureEvents = await this.fetchCalendarEvents(this.activeUserInfo.uid, modifedCurrentCalendarDate.toISOString())

            const filteredFutureEvents = futureEvents.filter((event) => {
              if (this.personalMeetings.findIndex((item) => item.privateEventId === event.privateEventId) > -1) {
                return false
              }

              return true
            })
            this.updatePersonalMeetings([...this.personalMeetings, ...filteredFutureEvents])

            this.isFetchingEvents = false
          }
        }
      }
    },

    addListeners() {
      if (!this.$refs.refCalendar) {
        return
      }

      document.querySelector('body').addEventListener('click', this.handleNextButtonCalendarClick)

      const $target = this.$refs.refCalendar.$el

      $target.addEventListener('mouseover', this.hoverFullCalendar)
    },

    removeListeners() {
      if (!this.$refs.refCalendar) {
        return
      }

      document.querySelector('body').removeEventListener('click', this.handleNextButtonCalendarClick)

      const $target = this.$refs.refCalendar.$el

      $target.removeEventListener('mouseover', this.hoverFullCalendar)
    },

    hoverFullCalendar(event) {
      const $target = this.$refs.refCalendar.$el
      const $el = event.target
      const $timeGridDayWrapper = $target.querySelector('.fc-timeGridDay-view')
      const $timeGridWeekWrapper = $target.querySelector('.fc-timeGridWeek-view')

      if ($timeGridDayWrapper) {
        $timeGridDayWrapper.querySelectorAll('.fc-timegrid-slot-lane-day_hover').forEach(($item) => {
          $item.classList.remove('fc-timegrid-slot-lane-day_hover')
        })

        if ($el.classList.contains('fc-timegrid-slot-lane')) {
          const titleText = $target.querySelector('.fc-toolbar-title').textContent
          const isSameDay = dayjs(titleText).startOf('day').isSame(dayjs().startOf('day'))
          const isBeforeDay = dayjs(titleText).startOf('day').isBefore(dayjs().startOf('day'))
          const time = $el.dataset.time
          const slotDate = dayjs(`${dayjs().format('YYYY-MM-DD')} ${time}`)

          if (!(isBeforeDay || (isSameDay && !dayjs().add(15, 'minute').isBefore(slotDate)))) {
            $el.classList.add('fc-timegrid-slot-lane-day_hover')
          }
        }
      } else if ($timeGridWeekWrapper) {
        $timeGridWeekWrapper.querySelectorAll('.fc-timegrid-slot-lane-week_hover').forEach(($item) => {
          $item.classList.remove('fc-timegrid-slot-lane-week_hover')
        })

        const isDayWeekToday = $timeGridWeekWrapper.querySelector('.fc-day-today')
        const isDayWeekFuture = $timeGridWeekWrapper.querySelector('.fc-day-future')

        if ($el.classList.contains('fc-timegrid-slot-lane') && (isDayWeekToday || isDayWeekFuture)) {
          $el.classList.add('fc-timegrid-slot-lane-week_hover')
        }
      }
    },

    toggleSidebar(isOpened = false) {
      this.isOpenedSidebar = isOpened
    },

    async setLastReadPage() {
      if (!this.activeUserInfo || !this.activeUserInfo.uid) {
        return
      }

      await this.$db
        .collection('users')
        .doc(this.activeUserInfo.uid)
        .set(
          {
            lastReadPages: {
              onlineMeeting: new Date()
            }
          },
          { merge: true }
        )
    },

    reloadCalendar() {
      const calednarApi = this.$refs.refCalendar && this.$refs.refCalendar.getApi()

      if (calednarApi) {
        this.calendarOptions.initialDate = calednarApi.getDate()
        this.calendarOptions.initialView = calednarApi.view.type
      }

      this.calendarKey = Math.random().toString(36).substring(2, 15)
    },

    setCalendarLang() {
      this.calendarOptions.locale = this.$i18n.locale === 'de' ? 'de' : ''

      this.calendarOptions.buttonText = {
        month: this.$i18n.t('vue.month'),
        week: this.$i18n.t('vue.week'),
        day: this.$i18n.t('vue.day'),
        list: this.$i18n.t('vue.list')
      }

      this.reloadCalendar()
    }
  }
}
</script>

<style lang="scss">
$border-color: #ebe9f1 !default;
$body-bg: #f8f8f8 !default;
$body-color: #6e6b7b !default;
$btn-border-radius: 0.358rem !default;
$primary: rgb(59, 134, 247) !default;
$fc-event-font-weight: 600;
$fc-event-font-size: 0.8rem;
$fc-event-padding-x: 0.5rem;
$fc-event-padding-y: 0.25rem;
$fc-toolbar-btn-padding: 0.438rem 0.5rem;
$fc-day-toggle-btn-padding: 0.55rem 1.5rem;
$text-muted: #b9b9c3 !default;

.full-calendar-wrapper {
  font-size: 14px;
  color: #6e6b8d;
  border-bottom: 1px solid var(--fc-border-color, #ddd);

  .fc-timeGridWeek-view {
    .fc-timegrid-slot-lane-week_hover {
      &:hover {
        background: darken($body-bg, 5%) !important;
        background-color: darken($body-bg, 5%) !important;
        cursor: pointer;
      }
    }
  }

  .fc-timeGridDay-view {
    .fc-timegrid-slot-lane-day_hover {
      &:hover {
        background: darken($body-bg, 5%) !important;
        background-color: darken($body-bg, 5%) !important;
        cursor: pointer;
      }
    }
  }

  .fc-timegrid-event-harness {
    min-height: 34px;
  }

  .fc-daygrid-event {
    .fc-daygrid-event-dot {
      display: none;
    }

    .fc-event-time,
    .fc-event-title {
      font-weight: 700;
    }
  }

  .bg-light-danger.fc-daygrid-event {
    color: rgb(255, 255, 255);
    background: rgba(234, 84, 85, 0.7) !important;
    border-color: rgba(234, 84, 85, 0.6);

    .fc-event-time,
    .fc-event-title {
      color: rgb(255, 255, 255);
    }
  }

  .bg-light-primary.fc-daygrid-event {
    color: rgb(255, 255, 255);
    //background: rgba(59, 134, 247, 0.7);
    //border-color: rgba(59, 134, 247, 0.6);
    padding: 0 !important;
    margin: 2px !important;

    .fc-event-time,
    .fc-event-title {
      color: rgb(255, 255, 255);
    }
  }

  .fc .fc-event.bg-light-disabled,
  .bg-light-disabled.fc-daygrid-event {
    color: rgb(255, 255, 255);
    background: rgb(208, 208, 208) !important;
    background-color: rgb(208, 208, 208) !important;
    border-color: rgba(89, 89, 89, 0.6) !important;

    padding: 0 !important;
    margin: 2px !important;

    .fc-event-time,
    .fc-event-title {
      color: rgb(255, 255, 255);
    }
  }

  .fc {
    a {
      color: inherit;
      font-size: 14px;
    }

    // Toolbar
    .fc-toolbar {
      @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;
      }

      .fc-prev-button,
      .fc-next-button {
        background-color: transparent;
        border-color: transparent;

        @media only screen and (max-width: 1024px) {
          margin-left: -8px;
        }

        .fc-icon {
          color: $body-color;

          @media only screen and (max-width: 1024px) {
            color: rgb(41, 166, 250);
            font-size: 24px !important;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background-color: transparent !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }

      .fc-prev-button {
        padding-left: 0 !important;
      }

      .fc-toolbar-chunk:first-child {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media only screen and (max-width: 1024px) {
          .fc-toolbar-title {
            position: absolute;
            left: 50%;
            margin-left: 0px !important;
            white-space: nowrap;
            transform: translateX(-50%);
          }
        }
      }

      .fc-toolbar-chunk:last-child {
        @media only screen and (max-width: 1024px) {
          width: 100%;

          .fc-button-group {
            margin-top: 25px;
            width: 100%;
          }
        }
      }

      .fc-button {
        padding: $fc-toolbar-btn-padding;

        @media only screen and (max-width: 1024px) {
          padding: 0;
        }

        &:active,
        &:focus {
          box-shadow: none;
        }
      }

      .fc-button-group {
        .fc-button {
          text-transform: capitalize;
          &:focus {
            box-shadow: none;
          }
        }

        .fc-button-primary {
          &:not(.fc-prev-button):not(.fc-next-button) {
            background-color: transparent;
            border-color: #275D73;
            color: #275D73;

            &.fc-button-active,
            &:hover {
              background-color: #275D73 !important;
              border-color: #275D73 !important;
              color: white;
            }
          }

          &.fc-sidebarToggle-button {
            border: 0;

            i,
            svg {
              height: 21px;
              width: 21px;
              font-size: 21px;
            }
          }
        }

        .fc-sidebarToggle-button {
          padding-left: 0;
          background-color: transparent !important;
          color: $body-color !important;

          &:not(.fc-prev-button):not(.fc-next-button):hover {
            background-color: transparent !important;
          }

          & + div {
            margin-left: 0;
          }
        }
        .fc-dayGridMonth-button,
        .fc-timeGridWeek-button,
        .fc-timeGridDay-button,
        .fc-listMonth-button {
          padding: $fc-day-toggle-btn-padding;

          &:last-child,
          &:first-child {
            border-radius: $btn-border-radius;
          }
          &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      & > * > :not(:first-child) {
        margin-left: 0rem;
      }

      .fc-toolbar-title {
        margin-left: 0.25rem;

        @media only screen and (max-width: 1024px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .fc--button:empty,
      .fc-toolbar-chunk:empty {
        display: none;
      }
    }

    .fc-view-harness {
      @media only screen and (max-width: 1024px) {
        height: calc(100vh - 360px) !important;
        min-height: 300px !important;
      }
    }

    // Calendar head & body common
    tbody td,
    thead th {
      border-color: $border-color;
      &.fc-col-header-cell {
        border-right: 0;
        border-left: 0;
      }
    }

    // Daygrid
    .fc-scrollgrid-section-liquid > td {
      border-bottom: 0;
    }
    .fc-daygrid-event-harness {
      .fc-event {
        font-size: $fc-event-font-size;
        font-weight: $fc-event-font-weight;
        padding: $fc-event-padding-y $fc-event-padding-x;
      }
      & + .fc-daygrid-event-harness {
        margin-top: 0.3rem;
      }
    }
    .fc-daygrid-day-bottom {
      margin-top: 0.3rem;
    }
    .fc-daygrid-day {
      padding: 5px;
      .fc-daygrid-day-top {
        flex-direction: row;
      }
    }

    // All Views Event
    .fc-daygrid-day-number,
    .fc-timegrid-slot-label-cushion,
    .fc-list-event-time {
      color: $body-color;
    }
    .fc-day-today {
      background: $body-bg !important;
      background-color: $body-bg !important;
    }

    .fc-day-today,
    .fc-day-future {
      &.fc-daygrid-day {
        &:hover {
          background: darken($body-bg, 5%) !important;
          background-color: darken($body-bg, 5%) !important;
          transition: all 0.1s linear;
          cursor: pointer;
        }
      }
    }

    .fc-day-past {
      .fc-daygrid-day-top {
        opacity: 0.3;
      }
    }

    .fc-h-event {
      //background-color: rgba(59, 134, 247, 0.7) !important;
      //border-color: rgba(59, 134, 247, 0.6) !important;

      .fc-event-title,
      .fc-event-main {
        color: rgb(255, 255, 255);
      }

      &.bg-light-disabled.fc-daygrid-event {
        color: rgb(255, 255, 255);
        background: rgb(208, 208, 208) !important;
        background-color: rgb(208, 208, 208) !important;
        border-color: rgba(89, 89, 89, 0.6) !important;

        .fc-event-time,
        .fc-event-title {
          color: rgb(255, 255, 255);
        }
      }
    }

    // Timegrid
    .fc-timegrid {
      .fc-scrollgrid-section {
        .fc-col-header-cell,
        .fc-timegrid-axis {
          border-color: $border-color;
          border-left: 0;
          border-right: 0;
        }
        .fc-timegrid-axis {
          border-color: $border-color;
        }
      }
      .fc-timegrid-axis {
        &.fc-scrollgrid-shrink {
          .fc-timegrid-axis-cushion {
            text-transform: capitalize;
            color: $text-muted;
          }
        }
      }
      .fc-timegrid-slots {
        .fc-timegrid-slot {
          height: 3rem;
          .fc-timegrid-slot-label-frame {
            text-align: center;
          }
        }
      }
      .fc-timegrid-divider {
        display: none;
      }
    }

    // List View
    .fc-list {
      border-color: $border-color;
      .fc-list-day-cushion {
        background: $body-bg;
      }
      .fc-list-event {
        &:hover {
          td {
            background-color: $body-bg;
          }
        }
        td {
          border-color: $border-color;
        }
      }

      .fc-list-event-dot {
        border-color: rgb(59, 134, 247);
      }

      .fc-event {
        &:hover {
          color: rgb(110, 107, 141);
        }
      }
    }

    .fc-v-event {
      // background-color: rgba(59, 134, 247, 0.7) !important;
      // border-color: rgba(59, 134, 247, 0.6) !important;

      .fc-event-main {
        color: rgb(255, 255, 255);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.blur-background {
  filter: blur(2px);
}
.online-meeting {
  min-height: 300px;
  &--wrapper {
    width: 100% !important;
    border-radius: 2px !important;
    padding: 5px !important;
    display: flex;
    line-height: 11px;
    font-size: 12px;

    height: 100%;
    &--row {
      align-items: center;
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
    }

    position: relative;

    .fc-event-time,
    .fc-event-title {
      white-space: nowrap;
    }
    .fc-event-time {
      margin-right: 3px;
    }

    .fc-event-title {
      overflow: hidden;
      &--ellipsis {
        max-width: 13ch;
        text-overflow: ellipsis;
      }
    }
  }

  &--visitor-score {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 10px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px; /* Rounded corners */
    padding: 1px 5px; /* Add some padding for better appearance */
  }

  &--agent-name {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 10px;
    color: #fff;
    background-color: rgba(21, 31, 61, 0.6);
    border-radius: 20px; /* Rounded corners */
    padding: 1px 5px; /* Add some padding for better appearance */
  }
  &--listMonth {
    display: none !important;
  }
  &--meeting-name {
    position: absolute;
    bottom: 2px;
    left: 2px;
    font-size: 12px;
  }
}
.message-data-sidebar {
  position: relative;
  z-index: 99999;
}
</style>
