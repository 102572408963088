var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("indeterminate-loading", {
        attrs: { isLoading: _vm.isFetchingEvents },
      }),
      _c(
        "div",
        {
          ref: "onlineMeetingCard",
          staticClass: "vx-card p-6 online-meeting",
          class: { "mt-2": !_vm.isMobile, "pt-3": _vm.isMobile },
        },
        [
          _c("MeetingSidebar", {
            attrs: {
              isSidebarActive: _vm.isOpenedSidebar,
              isMobile: _vm.isMobile,
            },
            on: { closeAppointmentsSidebar: _vm.toggleSidebar },
          }),
          _c(
            "div",
            {
              class:
                !_vm.HAS_ACCESS && !_vm.isMobileApp ? "blur-background" : "",
            },
            [
              _c(
                "vs-sidebar",
                {
                  staticClass: "message-data-sidebar items-no-padding",
                  attrs: {
                    "click-not-close": "",
                    "position-right": "",
                    parent: "body",
                    "default-index": "1",
                    color: "primary",
                    spacer: "",
                  },
                  model: {
                    value: _vm.isSidebarActiveLocal,
                    callback: function ($$v) {
                      _vm.isSidebarActiveLocal = $$v
                    },
                    expression: "isSidebarActiveLocal",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-6 flex items-center justify-between px-6",
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("inputs.message")))]),
                      _c("feather-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { icon: "XIcon" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.isSidebarActiveLocal = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("vs-divider", { staticClass: "mb-0" }),
                  _c(
                    _vm.scrollbarTag,
                    {
                      key: _vm.$vs.rtl,
                      tag: "component",
                      staticClass: "scroll-area--data-list-add-new",
                      attrs: { settings: _vm.settings },
                    },
                    [
                      _c("div", { staticClass: "chat__chats-list mb-8" }, [
                        _c(
                          "ul",
                          { staticClass: "chat__active-chats bordered-items" },
                          [
                            _c(
                              "li",
                              { staticClass: "cursor-pointer" },
                              [
                                _c("chat-message", {
                                  attrs: { chat: _vm.sidebarChatData },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoaded,
                      expression: "isLoaded",
                    },
                  ],
                  ref: "calendarWraper",
                  staticClass: "full-calendar-wrapper",
                },
                [
                  _c("full-calendar", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoaded,
                        expression: "isLoaded",
                      },
                    ],
                    key: _vm.calendarKey,
                    ref: "refCalendar",
                    staticClass: "full-calendar",
                    attrs: { options: _vm.modifiedCalendarOptions },
                    scopedSlots: _vm._u([
                      {
                        key: "eventContent",
                        fn: function (arg) {
                          return [
                            arg &&
                            arg.event &&
                            arg.event.extendedProps &&
                            arg.event.extendedProps.displayName
                              ? _c(
                                  "vx-tooltip",
                                  {
                                    class:
                                      arg &&
                                      arg.view &&
                                      ["listMonth", "dayGridMonth"].includes(
                                        arg.view.type
                                      )
                                        ? "online-meeting--wrapper online-meeting--wrapper--row"
                                        : "online-meeting--wrapper online-meeting--wrapper--column",
                                    style:
                                      arg &&
                                      arg.view &&
                                      arg.view.type === "dayGridMonth"
                                        ? "background-color: " +
                                          arg.backgroundColor +
                                          " !important; color: " +
                                          arg.textColor +
                                          "; "
                                        : "",
                                    attrs: {
                                      text:
                                        _vm.$t("inputs.title") +
                                        ": " +
                                        arg.event.title +
                                        ", " +
                                        _vm.$t("vue.time") +
                                        ": " +
                                        _vm.getEventStartTime(arg.event.start) +
                                        ", " +
                                        _vm.$t("vue.agent") +
                                        ": " +
                                        arg.event.extendedProps.displayName,
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "fc-event-time" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getEventStartTime(
                                              arg.event.start
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fc-event-title",
                                        class: {
                                          "fc-event-title--ellipsis":
                                            arg &&
                                            arg.view &&
                                            ![
                                              "timeGridDay",
                                              "listMonth",
                                            ].includes(arg.view.type),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(arg.event.title) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    arg &&
                                    arg.event &&
                                    arg.event.extendedProps &&
                                    arg.event.extendedProps.visitorScore &&
                                    arg.event.extendedProps.isVegaWidgetEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "online-meeting--visitor-score",
                                            class:
                                              "online-meeting--" +
                                              arg.view.type,
                                          },
                                          [
                                            _c("VisitorStatusIcon", {
                                              attrs: {
                                                score:
                                                  arg.event.extendedProps
                                                    .visitorScore,
                                                online: true,
                                                isVegaWidgetEnabled:
                                                  arg.event.extendedProps
                                                    .isVegaWidgetEnabled,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "online-meeting--agent-name",
                                        class:
                                          "online-meeting--" + arg.view.type,
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            arg.event.extendedProps.displayName
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "online-meeting--wrapper",
                                    style: arg.backgroundColor
                                      ? "background-color: " +
                                        arg.backgroundColor +
                                        " !important; color: " +
                                        arg.textColor +
                                        "; "
                                      : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "fc-event-time" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getEventStartTime(
                                              arg.event.start
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fc-event-title",
                                        class: {
                                          "fc-event-title--ellipsis":
                                            arg &&
                                            arg.view &&
                                            ![
                                              "timeGridDay",
                                              "listMonth",
                                            ].includes(arg.view.type),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(arg.event.title) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.HAS_ACCESS && !_vm.isMobileApp
            ? _c("online-meeting-message")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }