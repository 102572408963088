<template>
  <div class="online-meeting-page-wrapper">
    <div class="mb-base flex justify-between">
      <div class="action-buttons w-full mr-5">
        <div>
          <vs-button
            class="action-button"
            color="primary"
            type="filled"
            icon="add"
            :disabled="!HAS_ACCESS"
            :class="!HAS_ACCESS ? 'disabled-button' : ''"
            @click.prevent="toggleSidebar"
          >
            {{ $t('vue.addMeeting') }}
          </vs-button>
        </div>
        <div v-if="IS_ADMIN" class="select-agents">
          <multiselect
            ref="country"
            v-model="selectedAgents"
            :options="agents"
            :multiple="true"
            :max="10"
            :close-on-select="true"
            :clear-on-select="false"
            :placeholder="$t('vue.pleaseSelectAgents')"
            :selectLabel="''"
            :selectedLabel="''"
            :deselectLabel="''"
            label="name"
            track-by="name"
            :name="$t('inputs.agents')"
            open-direction="bottom"
            :data-vv-as="$t('inputs.agents')"
            :preserve-search="true"
            :preselect-first="false"
            @select="onSelectedAgentsUpdated"
            @remove="onRemovedAgent"
          >
          </multiselect>
        </div>
        <div v-if="IS_ADMIN" class="action-button child-action">
          <vs-button
            class="action-button"
            color="primary"
            type="filled"
            icon="filter"
            :disabled="this.selectedAgents.length === 0 || !canGetAgentMeetings"
            @click.prevent="getAgentMeetings"
          >
            {{ $t('vue.loadMeetings') }}
          </vs-button>
        </div>
      </div>
      <div>
        <vs-button color="primary" type="filled" icon="refresh" @click.prevent="refreshCalendar"> </vs-button>
      </div>
    </div>

    <MeetingList :modifiedMeetings="modifiedMeetings" :isMobile="isMobile" />
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

import { mapGetters } from 'vuex'
import { mcolorwheel } from '@/mixins/mcolorwheel'
import { mobile_check } from '@/mixins/mobile_check'

import MeetingList from './MeetingList.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    MeetingList,
    Multiselect
  },

  mixins: [mobile_check, mcolorwheel],

  data() {
    return {
      activeTab: 0,
      isMobile: false,
      agentMeetings: [],
      selectedAgents: [],
      canGetAgentMeetings: false
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      allmeetings: 'meetings',
      users: 'users',
      dialogs: 'dialogs'
    }),

    IS_ADMIN() {
      if (!this.activeUserInfo) return false

      return this.activeUserInfo.role === 'admin'
    },

    selectedAgentIds() {
      if (!this.selectedAgents || this.selectedAgents.length === 0) return []
      return this.selectedAgents.map((x) => x.id)
    },

    agents() {
      if (!this.activeUserInfo || !this.activeUserInfo.uid) return []
      if (!this.users || this.users.length === 0) return []

      const agents = this.users.filter((x) => x.id !== this.activeUserInfo.uid)
      return agents.map((x) => {
        const name = x.displayName && x.displayName.indexOf('@') === -1 ? x.displayName : `${x.firstname} ${x.lastname}`

        return {
          id: x.id,
          name
        }
      })
    },

    combinedMeetings() {
      return this.allmeetings ? this.allmeetings.concat(this.agentMeetings) : this.agentMeetings
    },

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('online-meeting') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    meetings() {
      const filteredMeetings = this.combinedMeetings.filter((x) => typeof x.type === 'undefined' || x.type === 'online-meeting')
      const sortedMeetings = [...filteredMeetings].sort((a, b) => {
        const dateA = a.created && a.created.seconds ? dayjs.unix(a.created.seconds) : dayjs(a.created)
        const dateB = b.created && b.created.seconds ? dayjs.unix(b.created.seconds) : dayjs(b.created)

        if (dateA.isBefore(dateB)) {
          return -1
        } else if (dateA.isAfter(dateB)) {
          return 1
        } else {
          return 0
        }
      })

      return sortedMeetings.reverse()
    },

    modifiedMeetings() {
      const modifiedMeetings = this.meetings.map((item) => {
        const start = item.date || (item.created && item.created.seconds) || item.created

        if (!start) {
          return false
        }

        item.title = item.name
        item.start = dayjs(start).toISOString()
        item.end = dayjs(dayjs(start).add(item.duration || 30, 'minute')).toISOString()
        item.allDay = false

        item.extendedProps = {
          calendar: item.status === 'canceled' ? 'Canceled' : 'Primary'
        }

        if (item.dialogId) {
          const widget = this.dialogs.find((x) => x.id === item.dialogId)
          if (widget) {
            item.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
          }
        }
        return item
      })

      const filteredMeetings = modifiedMeetings.filter((item) => !!item)

      return filteredMeetings
    }
  },

  created() {
    this.isMobile = this.mobileCheck()
  },

  methods: {
    onRemovedAgent(agent) {
      this.agentMeetings = this.agentMeetings.filter((x) => x.agentId !== agent.id)
    },
    onSelectedAgentsUpdated() {
      this.canGetAgentMeetings = true
    },
    async getAgentMeetings() {
      const agents = this.selectedAgentIds
      const colors = this.generateColorWheel('#275D73', agents.length)

      this.agentMeetings = []

      const combinedArray = agents.map((userId, index) => ({
        userId,
        color: colors[index]
      }))

      const twoDaysBeforeMS = 2 * 24 * 60 * 60 * 1000

      const twoDaysBefore = new Date().getTime() - twoDaysBeforeMS

      const queryMeetingsRef = await this.$db
        .collection('meetings')
        .where('companyId', '==', this.activeUserInfo.company)
        .where('agentId', 'in', agents)
        .where('date', '>', twoDaysBefore)
        .orderBy('date', 'desc')
        .limit(100)
        .get()

      queryMeetingsRef.docs.forEach((doc) => {
        const meeting = doc.data()
        meeting.id = doc.id
        const meetingColor = combinedArray.find((x) => x.userId === meeting.agentId).color
        meeting.backgroundColor = meetingColor.backgroundColor || '#287a9f'
        meeting.borderColor = meetingColor.backgroundColor || '#287a9f'
        meeting.textColor = meetingColor.textColor || '#287a9f'
        this.agentMeetings.push(meeting)
      })

      this.canGetAgentMeetings = false
    },
    refreshCalendar() {
      this.$serverBus.$emit('refresh-meetings-calendar')
    },
    toggleSidebar() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.$serverBus.$emit('open-meeting-sidebar')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled-button {
  background-color: #c4c4c4 !important;
}

.online-meeting-page-wrapper {
  @media only screen and (max-width: 1024px) {
    margin-top: -10px;

    .mb-base {
      margin-bottom: 15px !important;
    }

    .action-buttons {
      display: flex;
      flex-direction: column;

      > * {
        margin-top: 10px;
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    .action-buttons {
      display: flex;
      flex-direction: row;

      > * {
        margin-right: 10px;
      }
    }
    .select-agents {
      max-width: 500px;
    }
  }

  .action-button {
    min-width: 100px;
  }

  ::v-deep .multiselect__tag {
    color: #262629;
    background: #def1fe;
    &-icon {
      &:hover {
        background: #82ceff;
      }
    }
  }
}
</style>
